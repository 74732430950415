<!-- 解决方案 -->
<template>
  <div class="wrapper pageBox">
    <!-- banner -->
    <solutionBanner id="solutionBanner" ref="solutionBanner" :from="'Scheme'" />
    <!-- 二级导航 -->
    <navBar ref="navBar" :from="'Scheme'" />
    <!-- 行业分析 -->
    <div id="hyfx_div" class="fenxi-box">
      <h1 class="h1-title">行业分析</h1>
      <div class="fenxi conBox flex-between flex-align">
        <div class="imgs">
          <img class="img" src="@/assets/solution/cyyq/fenxi-bg.png">
        </div>
        <div>
          <div class="text">智慧园区的概念、理论及框架体系具有深远的意义，利用新一代信息技术整合系统的方式，更好地推动开发区和园区建设的良性发展。园区管理单位所承担的责任，与基层地方政府所承担的责任非常近似，不仅要负责整个园区的管理，同时还要负责园区的产业推进，招商引资、应急处置、内部服务等等各种服务与管理工作，促进园区的管理部门、企业、合作单位良性互动。</div>
          <div class="text" style="margin-top:15px">我们以为，园区的数字化发展以“融、简、真、优”为主线：融即数据互通互融，突破“多方子系统”的数据烟囱，将“人-车-物-环境”多维数据融合、互通管理；简即简化管理运维流程，解放人力束缚，释放“技术红利”，实现“云-边-端”模块化简易管理，提升运维效能；真即管理真正智能化，而非纯粹流程的数字化，构建“事前预警、事中告警和事后分析”的真智能管理流程，输出全价值链能力；优即优化服务体验，优化园区内“企业和个人”的整体入园体验，从单维度资源聚集，向多维度资源协同转变。</div>
        </div>
      </div>
    </div>
    <!-- 解决方案能力全景 -->
    <div id="faqj_div" class="conBox nlfa">
      <h1 class="h1-title">解决方案能力全景</h1>
      <img class="img" src="@/assets/solution/cyyq/nlfa.png">
      <img class="img1" src="@/assets/solution/cyyq/jjfabg.png">
    </div>
    <!-- 平台典型功能介绍 -->
    <carouselBanner id="ptgn_div" ref="carouselBanner" />
    <!-- 适用场景 -->
    <div id="sycj_div" class="sycj-div">
      <h1 class="h1-title">适用场景</h1>
      <sycj ref="sycj" class="conBox" :from="'Scheme'" />
    </div>
    <!-- 数字嘉园 美好生活 -->
    <footers ref="footers" />
  </div>
</template>

<script>
import solutionBanner from './components/solutionBanner.vue';
import sycj from './components/sycj.vue';
import footers from './components/footer.vue';
import carouselBanner from './components/carouselBanner.vue';
import navBar from './components/navBar.vue';
export default {
  name: 'Solution',
  components: { solutionBanner, sycj, footers, carouselBanner, navBar },
  data() {
    return {
    };
  },
  watch: {
  },
  mounted() {
  },
  methods: {
  }
};
</script>

<style lang="scss" scoped>

.flex-start {
  display: flex;
  justify-content: start;
}
.flex-between {
  display: flex;
  justify-content: space-between;
}
.flex-align {
  align-items: center;
}
.conBox {
  width: 1200px;
  margin: 0 auto;
}
.fenxi-box {
  padding-bottom: 80px;
  background: #F9FBFF;
  .fenxi {
    .imgs,.img{
      width: 594px;
      height: 380px;
    }
    .text {
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: left;
      color: #666666;
      line-height: 30px;
      padding-left: 50px;
      text-align: justify;
    }
  }
}
.nlfa {
  position: relative;
  text-align: center;
  margin-bottom: 80px;
  .img {
    width: 1200px;
    height: 763px;
    margin: auto;
  }
  .img1 {
    position: absolute;
    width: 600px;
    height: 500px;
    bottom: 4%;
    right: -22%;
  }
}
</style>

